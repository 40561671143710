import React, { useRef, useEffect, useState} from 'react';
import DataManager from "../data/dataManager";

//import "../../styles/board.css";
import "../../styles/notification_bar.css";

const NotificationBar = ({timed}) => {
  const [notificationText, setNotificationText] = useState("");//useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // console.log('Loaded Notification BAR!!!!: ' + notificationText);
    DataManager.addNotificationTextCallback('notificationBar', setNotificationText);
    DataManager.setIsNotificationDisplayedCallback(() => { return visible; });
  }, []);

  useEffect(() => {
    setVisible(notificationText.length > 0);
  }, [notificationText]);

  useEffect(() => {
    DataManager.setIsNotificationDisplayedCallback(() => { return visible; });
  }, [visible]);

  useEffect(() => {
    if(!visible || !timed){
      return;
    }
    const timeoutId = setTimeout(() => {
      console.log("timed event");
      DataManager.setNotificationBarText("");
    }, 5000); // 10000 milliseconds = 10 seconds

    return () => clearTimeout(timeoutId);
  }, [visible]);

  let colorTheme = DataManager.getNotificationBarColorTheme();

  return (<div className="notificationbar" style={{
    display : (!visible) ? 'none' : 'flex',
    width: '100%',
    borderColor: colorTheme.border,
    backgroundColor: colorTheme.background,
    color: colorTheme.text,
    zIndex: 5000
  }}>{notificationText}</div>);
};

export default NotificationBar;
