import React, { useRef, useEffect, useState} from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import DataManager, {ControlActions, SaveState} from "../data/dataManager";

const PaymentButton = ({amount, text}) => {
  const stripe = useStripe();

  const notifySessionSaveToDbState = (data) => {
    DataManager.setDatabaseState({
      saved: (!data.success) ? SaveState.Failed : SaveState.Saved,
      message : (!data.success) ? "Error: " + data.error : "",
      dateSaved: null,
      datePaid : data.paid
    });
  };

  const deleteSessionFromDb = () => {

  };

  const handleProceedToPayment = async () => {
    try {

      let actionCallback = DataManager.getActionCallbackForAction(ControlActions.SaveDatabase);
      let that = this;
      actionCallback((data) => {
        console.log('Save to database is done!! data: ' + JSON.stringify(data));

          // Create a Checkout Session
          //Test Cards: https://docs.stripe.com/testing#cards
          let hostname = DataManager.getCurrentHostName();
          let sessionId = DataManager.getSessionId();
          let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
              {
                amount     : 200, //$2 in cents
                session    : sessionId,
                returnUrl : 'https://'+ hostname + ':3000/checkout-result',
              })
          };
          fetch('https://'+ hostname + ':8080/create-checkout-session', requestOptions)
          .then(response => response.json())
          .then(session => {
            console.log('\n\n Stripe Session Returned: ' + session + '\n\n');
            // Redirect to Stripe Checkout
            stripe.redirectToCheckout({
              sessionId: session.id,
            })
            .then(result => {
              if (result.error) {
                alert(result.error.message);
                deleteSessionFromDb();
              }
            });
          })
          .catch(console.error);
      });
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while processing your payment.');
      deleteSessionFromDb();
    }

    DataManager.setDisplayPaymentControl(false);
  };

  return (<button onClick={handleProceedToPayment}>{text}</button>);
}

export default PaymentButton;
