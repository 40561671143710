import BaseTool from "./tools/base/baseTool";
import LineTool from "./tools/lineTool";
import RectTool from "./tools/rectTool";
import StraightLineTool from "./tools/straightLineTool";
import StraightArrowTool from "./tools/straightArrowTool";
import FilledRectTool from "./tools/filledRectTool";
import TextTool from "./tools/textTool";
import EraserTool from "./tools/eraserTool";
import SelectorTool from "./tools/selectorTool";
import PointerTool from "./tools/pointerTool";
import ImageTool from "./tools/imageTool";



export const DrawingControls = {
  None : "none",
  ClearOverlay: "clear-overlay",
  Line : "line",
  Rect : "rect",
  StraightLine : "straight-line",
  StraightArrow : "straight-arrow",
  FilledRect : "filled-rect",
  Text : "text",
  Pointer: "pointer",
  Eraser : "eraser",
  Selector : "selector",
  Image : "image",
  getControl : (value) => {
    switch(value)
    {
      case DrawingControls.Line:
        return DrawingControls.Line;
      case DrawingControls.StraightLine:
        return DrawingControls.StraightLine;
      case DrawingControls.StraightArrow:
        return DrawingControls.StraightArrow;
      case DrawingControls.Rect:
        return DrawingControls.Rect;
      case DrawingControls.FilledRect:
        return DrawingControls.FilledRect;
      case DrawingControls.Brush:
        return DrawingControls.Brush;
      case DrawingControls.Text:
        return DrawingControls.Text;
      case DrawingControls.Pointer:
        return DrawingControls.Pointer;
      case DrawingControls.ClearOverlay:
        return DrawingControls.ClearOverlay;
      case DrawingControls.Eraser:
        return DrawingControls.Eraser;
      case DrawingControls.Selector:
        return DrawingControls.Selector;
      case DrawingControls.Image:
        return DrawingControls.Image;
    }
    return DrawingControls.None;
  },
  getToolInstance : (controlType) => {
    let tool = null;
    switch(controlType){
      case DrawingControls.Line:
        tool = LineTool;
        break;
      case DrawingControls.StraightLine:
        tool = StraightLineTool;
        break;
      case DrawingControls.StraightArrow:
        tool = StraightArrowTool;
        break;
      case DrawingControls.Rect:
        tool = RectTool;
        break;
      case DrawingControls.FilledRect:
        tool = FilledRectTool;
        break;
      case DrawingControls.Text:
        tool = TextTool;
        break;
      case DrawingControls.Pointer:
        tool = PointerTool;
        break;
      case DrawingControls.Eraser:
        tool = EraserTool;
        break;
      case DrawingControls.Selector:
        tool = SelectorTool;
        break;
      case DrawingControls.Image:
        tool = ImageTool;
        break;
    }

    return tool;
  }
};

export const DrawingState = {
  None     : "none",
  Drawing  : "drawing",
  Writing  : "writing",
  Selected : "selected",
  Moving   : "moving",
  Editing  : "editing"
};

export const ControlActions = {
  Undo : "undo",
  SavePdf : "savePdf",
  Download : "download",
  Upload : "upload",
  UploadPdf : "uploadPdf",
  SaveDatabase : "database"
}

export const SelectActions = {
  None : "none",
  Move : "move",
  Edit : "edit"
};

export const SaveState = {
  None   : "none",
  Saved  : "saved",
  Failed : "failed"
};

export const ColorTheme = {
  Green : {
    border: "#137102",//"green",
    background: "#e5f9d9", //"green",
    text : "#137102"//"grey"
  },

  Red : {
    border: "red",
    background: "#FFB2B2",
    text : "red"
  },

  Yellow : {
    border : "orange",
    background : "yellow",
    text : "orange"
  },

  Grey : {
    border: "darkgrey",
    background: "grey",
    text : "darkgrey"
  }
}

export const ColorRGB = {
  "white"  : {r: 255, g: 255, b: 255},
  "black"  : {r: 0, g: 0, b: 0},
  "gray"   : {r: 128, g: 128, b: 128},
  "red"    : {r: 255, g: 0, b: 0},
  "orange" : {r: 255, g: 165, b: 0},
  "yellow" : {r: 255, g: 255, b: 0},
  "green"  : {r: 0, g: 255, b: 0},
  "blue"   : {r: 0, g: 0, b: 255},
  "pink"   : {r: 255, g: 192, b: 203},
  "purple" : {r: 128, g: 0, b: 128},
  "brown"  : {r: 150, g: 75, b: 0}
}

class DataManager {
  constructor() {
     if (DataManager.instance){
      return DataManager.instance;
     }

     this.state = {
       userId : '',
       userList : {},
       userOverlaysList : [],
       userPermissions : [],
       sessionId : '',
       currentObjectId : 0,
       currentDrawingTool : {
           x : 0,
           y : 0,
           //tool  : DrawingControls.Line,
           tool  : DrawingControls.Pointer,
           state : DrawingState.None,
           selectedArea : {
             x: 0,
             y: 0,
             width : 0,
             height : 0
           },
           selectedDelta : {
             dx : 0,
             dy : 0
           },
           selectAction : SelectActions.None,
           color : 'black',
           fadingLineList : [], //this will keep a list of lines that represent fading shape
           intervalId : -1,
        },
       databaseState : {
           saved       : SaveState.None,
           message     : "",
           dateSaved   : null,
           datePaid    : null
         },
       notificationBarColorTheme : ColorTheme.Red,
       userCanvas : null,
       userOverlayCanvas : null,
       hostname:'localhost',
       mobilePixelMultiplier : 1.5, //1.5,

       //callbacks will be useState setters, responsible
       //for redrawing the subscribed controls
       setDatabaseStateCallback : {},
       setCurrentColorCallbackList : {},
       setNotificationTextCallback : {},
       setDisplaySaveMenuCallback : {},
       isNotificationDisplayedCallback : null,
       setCurrentDrawingToolCallbackList : {},
       setWindowSizeCallbackList : {},
       setDisplayPaymentControlCallback : {},
       dismissMobileMenuCallbackList : {},
       setUserListCallbackList : {},
       setToolTipStateCallback : null,
       setUserIdCallbackList : [],
       setUserPermissionsCallbackList : [],
       toolInitCallback : null,
       toolCompleteCallback : null,
       actionCallbacks : {
         actions : {},
         callbacks : []
       },
     };

     DataManager.instance = this;
  }


  addDatabaseStateCallback(key, callback)
  {
      this.state.setDatabaseStateCallback[key] = callback;
  }

  setDatabaseState(state){
    console.log("SET DB STATE: " + JSON.stringify(state))
    this.state.databaseState = state;
    Object.keys(this.state.setDatabaseStateCallback).forEach(
      key => {
        // console.log('Setting users list to callback');
        this.state.setDatabaseStateCallback[key](this.state.databaseState);
    });
  }

  getDatabaseState(){
    return this.state.databaseState;
  }

  addDisplayPaymentControlCallback(key, callback) {
    this.state.setDisplayPaymentControlCallback[key] = callback;
  }

  setDisplayPaymentControl(value){
    Object.keys(this.state.setDisplayPaymentControlCallback).forEach(
      key => {
        this.state.setDisplayPaymentControlCallback[key](value);
    });
  }

  getMobilePixelMultiplier(){
    //TODO: multiplier only needed on canvas, need to fix canvas
    return this.state.mobilePixelMultiplier;
  }

  setCurrentHostName(hostname){
    this.state.hostname = hostname;
  }

  getCurrentHostName(){
    //return this.state.hostname;
    return "colabt.com";
  }

  getCurrentObjectId(){
    return this.state.currentObjectId;
  }

  incrementCurrentObjectId(){
    this.state.currentObjectId++;
  }

  getUserId(){
    return this.state.userId;
  }

  setUserId(userId){
    if(!userId){
      return;
    }
    // console.log("SETTING USER ID: " + userId + ", cbs: " + this.state.setUserIdCallbackList.length);
    this.state.userId = userId;
  }

  getUserList(){
    return this.state.userList;
  }

  setUserList(data){
    // console.log('setUsersList -> is called: ' + JSON.stringify(data));

    if(!this.state){
      // console.log("THIS.STATE is not defined...???");
      return;
    }

    if(!this.state.userId){
      return;
    }

    this.state.userList = data;
    // console.log('this.state.userList: ' + JSON.stringify(this.state.userList));
    Object.keys(this.state.setUserListCallbackList).forEach(
      key => {
        // console.log('Setting users list to callback');
        this.state.setUserListCallbackList[key](this.state.userList);
    });
  }

  getUserOverlaysList(){
    // console.log('>>>> OVERLAY LIST ');
    // console.log("getUserOverlayList: " + JSON.stringify(this.state));
    if(!this.state.userId){
      return [];
    }

    // console.log("getUserOverlaysList counting ")
    let zIndexCount = 0;
    let canvasList = (!this.state.userList) ? [] : Object.keys(this.state.userList).map((item, index) => {
      if (item == this.state.userId)
      {
         return;
      }

      let canvasId = "canvas_" + item;
      zIndexCount++;
      return <canvas id={canvasId}
       className="whiteboard overlay"
       key={canvasId}
       style={{
           //top: (window.innerWidth <= 790) ? '50px' : '150px',
           zIndex : zIndexCount,
           backgroundColor : "transparent"
         }}
       height={window.innerHeight}
       width={window.innerWidth}
         />;
       return item;
    });
    return canvasList;
  }

  getUserPermissions(){
    return this.state.userPermissions;
  }

  setUserPermissions(userPermissions){
    this.state.userPermissions = userPermissions;
    this.state.setUserPermissionsCallbackList.forEach(
      callback => {
        callback(userPermissions);
      }
    );
  }

  getSessionId(){
    return this.state.sessionId;
  }

  setSessionId(sessionId){
    this.state.sessionId = sessionId;
  }

  getCurrentDrawingTool(){
    return this.state.currentDrawingTool;
  }

  setCurrentDrawingTool(drawingTool){
    // console.log('Set TOOL: ' + JSON.stringify(drawingTool));
    let editControlsVisibility = false;
    if(this.state.currentDrawingTool.tool == DrawingControls.Text){
      editControlsVisibility = true;
    }

    let settingDifferentTool = (this.state.currentDrawingTool.tool != drawingTool.tool);

    //Shutting down the old tool
    if(!!settingDifferentTool && !!this.state.toolCompleteCallback){
      let currentTool = DrawingControls.getToolInstance(this.state.currentDrawingTool.tool);
      this.state.toolCompleteCallback(currentTool);
    }

    this.state.currentDrawingTool = drawingTool;

    //Initializing new tool

    if(!!settingDifferentTool && !!this.state.toolInitCallback){
      let currentTool = DrawingControls.getToolInstance(this.state.currentDrawingTool.tool);
      this.state.toolInitCallback(currentTool);
    }

    if(!!editControlsVisibility){
      TextTool.setControlVisible();
    }

    Object.values(this.state.setCurrentDrawingToolCallbackList).forEach(
      callback => {
        callback(drawingTool);
    });
  }

  getCurrentColor(){
    return this.state.currentDrawingTool.color;
  }

  isNotificationDisplayed(){
    if(!this.state.isNotificationDisplayedCallback){
      return false;
    }
    return this.state.isNotificationDisplayedCallback();
  }

  setCurrentColor(color){
    this.state.currentDrawingTool.color = color;
    Object.values(this.state.setCurrentColorCallbackList).forEach(
      callback => {
        callback(color);
    });
  }

  setWindowSize(width, height){
    Object.values(this.state.setWindowSizeCallbackList).forEach(
      callback => {
        callback({width, height});
    });
  }

  dismissAllMobileMenusExceptKey(keyNotToDismiss){
    Object.keys(this.state.dismissMobileMenuCallbackList).forEach(
      key => {
        if(key == keyNotToDismiss){
          return;
        }
        let callback = this.state.dismissMobileMenuCallbackList[key];
        callback();
    });
  }

  addDisplaySaveMenuCallback(key, callback) {
    this.state.setDisplaySaveMenuCallback[key] = callback;
  }

  setDisplaySaveMenu(value){
    Object.keys(this.state.setDisplaySaveMenuCallback).forEach(
      key => {
          this.state.setDisplaySaveMenuCallback[key](value);
      });
  }

  addCurrentColorCallback(key, callback){
    this.state.setCurrentColorCallbackList[key] = callback;
  }

  addNotificationTextCallback(key, callback){
    // console.log('Adding Callback to set text callback');
    //TODO:need to notify the boardPage that notificationBar is visible
    this.state.setNotificationTextCallback[key] = callback;
  }

  setNotificationBarText(text, colorTheme){
    this.state.notificationBarColorTheme = (!colorTheme) ? ColorTheme.Red : colorTheme;

    Object.keys(this.state.setNotificationTextCallback).forEach(key => {
      this.state.setNotificationTextCallback[key](text);
    });
  }

  getNotificationBarColorTheme(){
    return this.state.notificationBarColorTheme;
  }

  setIsNotificationDisplayedCallback(callback){
    this.state.isNotificationDisplayedCallback = callback;
  }

  setToolTipState(state){
    if(this.state.setToolTipStateCallback != null){
      this.state.setToolTipStateCallback(state);
    }
  }

  addToolTipStateCallback(callback){
    this.state.setToolTipStateCallback = callback;
  }

  addCurrentDrawingToolCallback(key, callback){
    this.state.setCurrentDrawingToolCallbackList[key] = callback;
  }

  addWindowResizeCallback(key, callback){
    this.state.setWindowSizeCallbackList[key] = callback;
  }

  addDismissMobileMenuCallback(key, callback){
    this.state.dismissMobileMenuCallbackList[key] = callback;
  }

  addSetUserListCallbackList(key, callback){
    this.state.setUserListCallbackList[key] = callback;
  }

  addSetUserIdCallbackList(callback){
    this.state.setUserIdCallbackList = [callback];
  }

  addSetUserPermissionsCallbackList(callback){
    this.state.setUserPermissionsCallbackList = [callback];
  }

  setToolInitCallback(callback){
    this.state.toolInitCallback = callback;
  }

  setToolCompleteCallback(callback){
    this.state.toolCompleteCallback = callback;
  }

  setActionCallback(action, callback){
    this.state.actionCallbacks.actions[action] = callback;
  }

  getActionCallbackForAction(action) {
    return this.state.actionCallbacks.actions[action];
  }

  setUserCanvas(canvas){
    this.state.userCanvas = canvas;
  }

  setUserOverlayCanvas(canvas){
    this.state.overlayCanvas = canvas;
  }

  getDayPaidDaysLeft(paidTimestamp){
    let currentDate = Date.now();
    let diff = Math.round((currentDate - paidTimestamp) / (1000 * 60 * 60 * 24));
    let result = 30 - diff;
    return result;
  }

};


const instance = new DataManager();
Object.freeze(instance);
export default instance;
