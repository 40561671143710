import React, { useRef, useEffect, useState} from 'react';
import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

export const FontOptions = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Calibri', label: 'Calibri' },
  { value: 'Courier New', label: 'Courier New' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Helvetica', label: 'Helvetica' },
  { value: 'Lucida Console', label: 'Lucida Console' },
  { value: 'Tahoma', label: 'Tahoma' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Trebuchet MS', label: 'Trebuchet MS' },
  { value: 'Verdana', label: 'Verdana' },
];

export const SizeOptions = [
  { value: 32, label: '32px'},
  { value: 28, label: '28px'},
  { value: 24, label: '24px'},
  { value: 20, label: '20px'},
  { value: 18, label: '18px'},
  { value: 16, label: '16px'},
  { value: 14, label: '14px'},
  { value: 12, label: '12px'},
  { value: 10, label: '10px'},
  { value: 8,  label: '8px'},
];

export const StyleOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'bold', label: 'Bold' },
  { value: 'italic', label: 'Italic' },
];


class TextTool extends BaseTool {
  constructor(){
    super();

    this.state = {
      value : '',
      textBoxData : {},
      editedObject : null,
      style : '',
      size  : '',
      font  : '',
      redrawFlag : false,
      visible : false,
      downPressed : false,
      setControlVisibleCallback : null,
      redrawCallback: null,
      updateValueCallback: null,
      editCompletedCallback : null

    }

    TextTool.instance = this;
  }

  getValue(){
    return this.state.value;
  }

  setValue(value, data){
    // console.log('Value updated.. : ' + JSON.stringify(data));
    this.state.value = value;
    this.state.textBoxData = data;
  }

  getStyle(){
    return this.state.style;
  }

  setStyle(style){
    this.state.style = style;
  }

  getSize(){
    return this.state.size;
  }

  setSize(size){
    this.state.size = size;
  }

  getFont(){
    return this.state.font;
  }

  setFont(font){
    this.state.font = font;
  }

  setEditedObject(data) {
    this.state.editedObject = data;
  }

  //TODO: may need to set some incrementing state
  // variable in textControl to trigger the redraw event
  // on every click

  setRedrawCallback(callback){
    this.state.redrawCallback = callback;
  }

  setVisibleCallback(callback){
    this.state.setControlVisibleCallback = callback;
  }

  setUpdateValueCallback(callback){
    this.state.updateValueCallback = callback;
  }

  setControlVisible(){
    let current =  DataManager.getCurrentDrawingTool();
    // console.log('VISIBLE CURRENT: ' + JSON.stringify(current));
    let visible = this.state.visible;
    this.state.visible = (current.tool === DrawingControls.Text &&
        (current.state === DrawingState.Writing || current.state === DrawingState.Editing));
    this.state.setControlVisibleCallback(this.state.visible);

    if(visible == this.state.visible){
      this.state.redrawFlag = !this.state.redrawFlag;
      this.state.redrawCallback(this.state.redrawFlag);
    }

  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      this.setEditedObject(null);
      if(!!this.state.editCompletedCallback)
      {
        this.state.editCompletedCallback();
        this.state.editCompletedCallback = null;
      }
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let current = DataManager.getCurrentDrawingTool();
    let sessionId = DataManager.getSessionId();
    if (this.state.downPressed == true) { return; }

    // console.log('>>>>>>>>> Text Tool Mouse Down AND VALUE: '
    //  + this.state.value);

    if(!!this.state.value)
    {
      let info = {
        x : current.x,
        y : current.y,
        text : this.state.value,
        textBoxData : this.state.textBoxData,
        selected : false
       }

      let style = {
          color: current.color,
          fontSize: this.state.size,
          fontFamily: this.state.font,
          fontStyle: this.state.style
        }
      this.draw(info, style, canvas, canvasContext, true, true, sessionId);
      this.state.value = '';
      this.setEditedObject(null);
      this.state.updateValueCallback(this.state.value);
    }

    current.x = e.clientX || e.touches[0].clientX;
    current.y = e.clientY || e.touches[0].clientY;
    current.maxWidth = 0;
    current.maxHeight = 0;

    if(current.state != DrawingState.Editing) {
      current.state = DrawingState.Writing;
    }

    DataManager.setCurrentDrawingTool(current);
    this.setControlVisible();
    this.state.downPressed = true;
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    // if(!this.state.downPressed)
    // {
    //   console.log('Text Control move exits!!');
    //   return;
    // }
    // console.log('Text Control move =>>>>>!!');
    // let current = DataManager.getCurrentDrawingTool();
    // current.x = e.clientX || e.touches[0].clientX;
    // current.y = e.clientY || e.touches[0].clientY;
    // current.maxWidth = 0;
    // current.maxHeight = 0;
    // DataManager.setCurrentDrawingTool(current);
    // this.setControlVisible();
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    // console.log('MOUSE UP');
    if(!this.state.downPressed)
    {
      return;
    }

    let current = DataManager.getCurrentDrawingTool();
    this.state.value = '';

    //KS TODO: need to fix this to switch back to selector control
    if(current.state === DrawingState.Editing)
    {
      let color = current.color;
      current = {
        x: 0,
        y: 0,
        tool: DrawingControls.Selector,
        state: DrawingState.None,
        color: color
      };
    }

    // console.log('ON MOUSE UP current: ' + JSON.stringify(current));
    DataManager.setCurrentDrawingTool(current);
    this.state.updateValueCallback(this.state.value);
    DataManager.incrementCurrentObjectId();
    this.setControlVisible();
    this.state.downPressed = false;
  }

  onKeyUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
    let keyCode = e.keyCode;

    //if(keyCode == 13){
    if(e.key === 'Escape' || keyCode == 27){
      //console.log('KEY 27 pressed');
      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();

      if(current.state === DrawingState.Editing)
      {
        let color = current.color;
        current = {
          x: 0,
          y: 0,
          tool: DrawingControls.Selector,
          state: DrawingState.None,
          color: color
        };

      }

      current.state = DrawingState.None;

      let info = {
        x : current.x,
        y : current.y,
        text : this.state.value,
        textBoxData : this.state.textBoxData,
        selected : false
      };

      let style = {
        color: current.color,
        fontSize: this.state.size,
        fontFamily: this.state.font,
        fontStyle: this.state.style
      }

      this.draw(info, style, canvas, canvasContext, true, true, sessionId);
      this.state.value = '';
      this.setEditedObject(null);
      DataManager.setCurrentDrawingTool(current);
      this.state.updateValueCallback(this.state.value);
      this.setControlVisible();
      this.state.downPressed = false;
    }
  }

  wrapText(context, text, x, y, maxWidth, lineHeight, style){
    const words = text.split(' ');
    let line = '';

    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && i > 0) {
        context.fillText(line, x, y);
        line = words[i] + ' ';
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  drawFormattedText(context, text, x, y, width, height, style){
    //console.log('Called drawFormattedText');
    const { fontSize = 20,
      fontFamily = 'Arial',
      fontStyle = 'normal',
      color = 'black',
      textAlign = 'left',
      textBaseline = 'top' } = style;

    // Handle manual and automatic line breaks
    const lines = text.split('\n');
    //let metrics = context.measureText(text);
    //console.log('Drawing Line length: ' + lines.length);

    context.beginPath();
    context.font = fontStyle + ' '+ fontSize + 'px ' + fontFamily;
    context.textAlign = textAlign;
    context.textBaseline = textBaseline;
    context.fillStyle = color;
    let lineObjects = [];

    lines.forEach((line) => {
      // Wrap text if line exceeds canvas width
      let metrics = context.measureText(line);
      let lineHeight = fontSize * 1.6
      lineObjects.push({
        x: x,
        y: y,
        width: metrics.width,
        height: lineHeight,
        text: line,
        style: style
      });
      //this.wrapText(context, line, x, y, metrics.width, lineHeight, style);
      context.fillText(line, x, y);
      y += lineHeight; // Increase y position for the next line
    });
    context.stroke();
    return lineObjects;
  }

  onEditSelection(data,
    canvas, context,
    overlayCanvas, overlayContext, onEditCompletedCallback){

      // console.log("Data passed to TEXT EDIT: " + JSON.stringify(data));
      this.state.value = data.text;
      this.state.style = data.fontStyle;
      this.state.size = data.fontSize;
      this.state.editCompletedCallback = onEditCompletedCallback;
      this.setEditedObject(data);
      overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height)
      context.clearRect(
          data.x * window.innerWidth,
          data.y * window.innerHeight,
          data.w * window.innerWidth,
          data.h * window.innerHeight);

      let currentTool = {
        x: data.x * window.innerWidth,
        y: data.y * window.innerHeight,
        tool: DrawingControls.Text,
        state: DrawingState.Editing,
        color: data.color
      };

      // console.log('>>> KS: DATA for current tool: ' + JSON.stringify(data));
      // console.log('>>> KS: Setting current tool: ' + JSON.stringify(currentTool));
      DataManager.setCurrentDrawingTool(currentTool);
      this.state.updateValueCallback(this.state.value);
      this.state.setControlVisibleCallback(true);
      this.state.redrawCallback(true);
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){
      // console.log('DRAW EMITTED TEXT userId: '+ data.userId);
      let width = canvas.width;
      let height = canvas.height;
      // console.log('TEXT EMITTED DATA: ' + JSON.stringify(data));

      let denormalizedTextBoxData = (!!data.textBoxData) ? {
        x : data.textBoxData.x * width,
        y : data.textBoxData.y * height,
        width : data.textBoxData.width * width,
        height : data.textBoxData.height * height,
        text : data.text
      } : null;

      let info = {
        x : data.x * width,
        y : data.y * height,
        text : data.text,
        textBoxData : denormalizedTextBoxData,
        selected : data.selected
      };

      let style = {
        color: data.color,
        fontSize: data.fontSize,
        fontFamily: data.fontFamily,
        fontStyle: data.fontStyle
       };

      //overlayContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      //Clearing of overlay should done outside of this call..
      this.draw(info, style, canvas,
        (!data.selected) ? context : overlayContext,
        data.final, false, sessionId);
  }

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    //TODO: handle the rendering text on temp canvas as it is being typed
    const { text, textBoxData, x, y, selected } = info;
    const { fontSize = 20, fontFamily = 'Arial', fontStyle = 'normal', color = 'black', textAlign = 'left', textBaseline = 'top' } = style;

    // console.log('Draw is called for Text Tool');
    // console.log('Context: ' + (!context ? "FALSE" : "TRUE") );
    // console.log('INFO: ' + JSON.stringify(info));
    // console.log('STYLE: ' + JSON.stringify(style));
    if(this.state.editedObject != null){
      console.log('EDITED OBJECT IN DRAW: ' + JSON.stringify(this.state.editedObject));
    } else {
      console.log('EDITED OBJECT IN DRAW IS NULL');
    }

    let lineObjects = [];
    if(!textBoxData){
      context.beginPath();
      context.font = fontStyle + ' '+ fontSize + 'px ' + fontFamily;
      context.textAlign = textAlign;
      context.textBaseline = textBaseline;
      context.fillStyle = color;
      context.fillText(text, x, y);
      context.stroke();
    } else {
      lineObjects = this.drawFormattedText(context, text, x, y,
        textBoxData.width, textBoxData.height, style);
    }

    if(!emit) { return; }

    let metrics = context.measureText(text);
    let width = canvas.width;
    let height = canvas.height;
    let userId = DataManager.getUserId();
    let drawObjectId = DataManager.getCurrentObjectId();
    let current = DataManager.getCurrentDrawingTool();

    let normalizedBoxData = (!textBoxData) ? null : {
      x : textBoxData.x / width,
      y : textBoxData.y / height,
      width : textBoxData.width / width,
      height : textBoxData.height / height,
      text : textBoxData.text
    }

    let lineHeight = 0;
    let lineWidth = 0;
    for(let i = 0; i < lineObjects.length; i++){
      lineHeight += lineObjects[i].height;
      lineWidth = Math.max(lineWidth, lineObjects[i].width);
    }

    EventManager.onDrawingDataEmit({
      sessionId: sessionId,
      userId: userId,   //userID will be set only when emit is set to true, meaning user drew the line
      actingUserId: userId,
      objectId: drawObjectId,
      control: DrawingControls.Text,
      text: text,
      fontSize: fontSize,
      fontFamily: fontFamily,
      fontStyle: fontStyle,
      final: isFinal,
      selected: selected,
      drawingState: current.drawingState,
      x: x / width,
      y: y / height,
      w: lineWidth / width, //metrics.width / width,
      h: lineHeight / height, //metrics.height / height,
      color: color,
      textBoxData: normalizedBoxData,
      lineObjects: lineObjects,
      editedObject: this.state.editedObject
    });
  }
}

const instance = new TextTool();
Object.freeze(instance);
export default instance;
