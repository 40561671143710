import React, { useRef, useEffect, useState } from 'react';
import DataManager, {DrawingState, DrawingControls} from "../data/dataManager";
import QRCode from 'qrcode';
import ImageTool from "../data/tools/imageTool";
import "../../styles/board.css";

const QRCodeLinkControl = ({url, session, user}) => {
  const canvasRef = useRef(null);
  let current = DataManager.getCurrentDrawingTool();

  const [fullUrl, setFullUrl] = useState();
  const [qrControlVisible, setQrControlVisible] = useState(false);
  const [qrColor, setQrColor] = useState(current.color);
  const [redrawFlag, setRedrawFlag] = useState(false);

  const handleUrlClick = () => {
      window.open(url+'/'+session+'/'+user, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    setFullUrl(url + "/" +session + "/" + user);
  },[url, session, user]);

  useEffect(() => {
    if (canvasRef.current) {
      // console.log(fullUrl);
      QRCode.toCanvas(canvasRef.current, fullUrl, {
        width: 100,
        height: 100, // This line is actually redundant since setting width adjusts both width and height equally
        margin: 1, // Optional: Sets the margin (in pixels) around the QR code
        color: {
          dark: '#858585', // Grey color for the QR Code
          light: '#ffffff' // Background color for the QR Code (white)
        },
        } , function (error) {
        if (error) console.error(error);
        console.log('Success!');
      });
    }

    if(window.innerWidth > 950){
      ImageTool.setVisibleCallback('qrCodeTool', setQrControlVisible);
      ImageTool.setRedrawCallback('qrCodeTool', setRedrawFlag);
    }
    DataManager.addCurrentColorCallback("imageControl", setQrColor);
  }, [fullUrl]);

  return (
    //<div className="camqrlink overlay" onClick={handleUrlClick}>
    <div onClick={handleUrlClick} style={{
      position: "fixed",
      top: current.y + (current.maxHeight / 2 - 65), //(current.y + current.maxHeight) / 2 - 130,
      left: current.x + (current.maxWidth / 2 - 65), //(current.x + current.maxWidth) / 2 - 130,
      height: '130px',
      width: '130px',
      visibility: !!qrControlVisible ? 'visible' : 'collapse',
      backgroundColor: "white",
      zIndex: 1000,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'grey',
      borderRadius: '12px'
    }}>
      <canvas ref={canvasRef} style={{
        marginTop: '10px',
        marginLeft: '13px',
        height: 100,
        width: 100
      }} />
      <div style = {{
        marginTop : '-5px',
        textAlign : 'center',
        color: 'grey'
      }}>camera</div>
    </div>
  );
};


export default QRCodeLinkControl;
