//import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsLib from "pdfjs-dist/webpack";

class DimensionsManager {
  constructor() {
     if (DimensionsManager.instance){
      return DimensionsManager.instance;
     }

     DimensionsManager.instance = this;
   }

   getPdfDimensions(file, callback){
     if(!file){
       callback({ w : -1, h : -1});
     }

     const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      const pdfData = new Uint8Array(e.target.result);

      // Load the PDF
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

      // Get the first page
      const page = await pdf.getPage(1); // Change page number if needed

      // Get the page dimensions
      const viewport = page.getViewport({ scale: 1 });
      const width = viewport.width;
      const height = viewport.height;

      console.log(`Page Dimensions: Width = ${width}, Height = ${height}`);
      callback({ w: width, h: height});
    };

    fileReader.readAsArrayBuffer(file);
   }

   getX(origX){
     //TODO: return correct x coordinate, using screen pixel size
     throw "Not implemented";
   }

   getY(origY){
     //TODO: return correct y coordinate, using screen pixel size
     throw "Not implemented";
   }

   getWdith(origWidth){
     //TODO: return correct y coordinate, using screen pixel size
     throw "Not implemented";
   }

   getHeight(origHeight){
     //TODO: return correct y coordinate, using screen pixel size
     throw "Not implemented";
   }
};

const instance = new DimensionsManager();
Object.freeze(instance);
export default instance;
