import React, { useRef, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave, faSlash, faPen, faPencil,
  faSquareFull, faFont,
  faArrowPointer, faRotateLeft, faFilePdf, faEraser, faBorderStyle, faArrows} from '@fortawesome/free-solid-svg-icons';
import {faSquare, faClipboard, faSquareDashed} from '@fortawesome/free-regular-svg-icons';
import DataManager, {DrawingState, DrawingControls, SelectActions} from "../data/dataManager";
import EventManager from "../data/eventManager";
import TextTool from "../data/tools/textTool";
import SelectorTool from "../data/tools/selectorTool";

import "../../styles/board.css";

//TODO: maybe rename this.
//TODO: will have a menu of buttons, "move, copy, edit"
//      will add Ctrl+X, Ctrl+C and Delete button call listeners


const EditSelectionControl = ({canvasRef, overlayRef}) => {


  const [editSelectionVisible, setEditSelectionVisible] = useState(false);
  const [currentColor, setCurrentColor] = useState(DataManager.getCurrentColor());
  const [currentAction, setCurrentAction] = useState(SelectorTool.getSelectAction());
  const [canvasDimensions, setCanvasDimensions] = useState(null);


  const onSelectMoveAction = () => {
    SelectorTool.setSelectAction(SelectActions.Move);
    //setCurrentAction(SelectActions.Move);
  };

  const onSelectEditAction = () => {
    //console.log('EDIT CLICKED!!');

    let current = DataManager.getCurrentDrawingTool();
    // console.log('EDIT SELECTED CONTROL: ' +
    //   JSON.stringify(current.selectedObjects[0]));
    if(current.selectedObjects[0].control == DrawingControls.Text){
      let canvas = canvasRef.current;
      let overlayCanvas = overlayRef.current;
      let canvasContext = canvas.getContext('2d');
      let overlayCanvasContext = overlayCanvas.getContext('2d');
      TextTool.onEditSelection(current.selectedObjects[0],
          canvas, canvasContext, overlayCanvas, overlayCanvasContext, () => {
            EventManager.onForceRefreshRemote();
          });

    }

    SelectorTool.setSelectAction(SelectActions.Edit);
    //setCurrentAction(SelectActions.Edit);
  };


  useEffect(() => {
    SelectorTool.setCurrentSelectActionCallback(setCurrentAction);
    SelectorTool.setEditSelectionVisibleCallback(setEditSelectionVisible);
    //SelectorTool.setSelectAction(SelectActions.Move);
    DataManager.addCurrentColorCallback("editSelectionControl", setCurrentColor);
    DataManager.addWindowResizeCallback("editSelectionControl", setCanvasDimensions);
  },[]);

  useEffect(() => {
    //TODO: use this to extract width and height from,
    // selected area and use them instead of maxWidth and maxHeight
    // to position this control..
    if(!canvasRef.current || !overlayRef.current){
      return;
    }

    const canvas = canvasRef.current;
    const overlayCanvas = overlayRef.current;

    setCanvasDimensions({
      width :  canvas.width,
      height : canvas.height
    });

  }, [canvasRef, overlayRef]);


  let current = DataManager.getCurrentDrawingTool();
  //console.log('SELECTOR TOOL: ' + JSON.stringify(current));
  //console.log('SELECTOR TOOL ACTION: ' + JSON.stringify(currentAction));
  //console.log('SELECTOR TOOL SELECTED AREA: ' + JSON.stringify(current.selectedArea));
  //console.log('SELECTOR TOOL CURRENT SELECTED AREA 2: ' + JSON.stringify(canvasDimensions));

  let areaWidth = (!canvasDimensions || !current.selectedArea) ? current.maxWidth : current.selectedArea.width * canvasDimensions.width;
  areaWidth = (areaWidth < 0) ? 0 : areaWidth;
  let areaHeight = (!canvasDimensions || !current.selectedArea) ? current.maxHeight : current.selectedArea.height * canvasDimensions.height;
  areaHeight = (areaHeight < 0) ? areaHeight : 0;
  let originX = (!canvasDimensions || !current.selectedArea) ? current.x : current.selectedArea.x * canvasDimensions.width;
  let originY = (!canvasDimensions || !current.selectedArea) ? current.y : current.selectedArea.y * canvasDimensions.height;

  //TODO: seems on resize the width/height changes do not take effect..

  // console.log('SELECTOR TOOL CURRENT SELECTED AREA 2: ' + JSON.stringify({
  //   originX  : originX,
  //   originY  : originY,
  //   areaWidth : areaWidth,
  //   areaHeight : areaHeight
  // }));


  /*
  color : currentColor,
  backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
  fontWeight : "bold"
  */

  // console.log('DARWING EDIT SELECTOR VISIBLE: ' + editSelectionVisible);
  return (<div className="select-tools"  style={{
    position: "fixed",
    //top : current.y - 36,
    //left : current.x + current.maxWidth - 133,
    top: originY + areaHeight - 36,
    left: originX + areaWidth - 133,
    // top: currentSelectedArea.y - 36,
    // left: currentSelectedArea.x + currentSelectedArea.width - 133,
    visibility: !!editSelectionVisible ? 'visible' : 'collapse',
    backgroundColor: "white", //"transparent",
    zIndex: 1000
  }}>
    <div className="btn selectmove" onClick={onSelectMoveAction} style={(currentAction == SelectActions.Move)
    ? {
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        color : currentColor,
        backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
        fontWeight : "bold"
      }
    : {
        borderRight : "1px",
        borderRightStyle : "solid",
        borderRightColor : "grey",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px"
      }
    }><FontAwesomeIcon icon={faArrows} aria-hidden="true"/></div>
    <div className="btn selectedit" onClick={onSelectEditAction} style={(currentAction == SelectActions.Edit)
      ? {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
          color : currentColor,
          backgroundColor : (currentColor == "white" || currentColor == "yellow" || currentColor == "pink") ? "black" : "transparent",
          fontWeight : "bold"
        }
      : {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px"
        }
    }><FontAwesomeIcon icon={faPencil} aria-hidden="true"/></div>
  </div>);
};

export default EditSelectionControl;
