import BaseTool from "./base/baseTool"
import DataManager, {DrawingState, DrawingControls} from "../dataManager"
import EventManager from "../eventManager";

class ImageTool extends BaseTool {
  constructor(){
    super();

    this.state = {
      redrawFlag : false,
      visible : false,
      setControlVisibleCallback : {},
      redrawCallback: {},
      //postDrawCallback: null
      postDrawCallback: []
      //updateValueCallback: null
    }

    ImageTool.instance = this;
  }

  setPostDrawCallback(callback){
    //this.state.postDrawCallback = callback;
    this.state.postDrawCallback.push(callback);
    // console.log('IMAGE CALLBACK LENGTH: ' + this.state.postDrawCallback.length);
  }

  setRedrawCallback(key, callback){
    this.state.redrawCallback[key] = callback;
  }

  setVisibleCallback(key, callback){
    this.state.setControlVisibleCallback[key] = callback;
  }

  // setUpdateValueCallback(callback){
  //   this.state.updateValueCallback = callback;
  // }

  setControlVisible(value){
    // console.log('Set control visible: ' + value);
    let current = DataManager.getCurrentDrawingTool();
    let visible = this.state.visible;
    this.state.visible = value;
    Object.keys(this.state.setControlVisibleCallback).forEach(key => {
      this.state.setControlVisibleCallback[key](
        this.state.visible,
        current.x, current.y,
        current.maxWidth, current.maxHeight);
    });
    //this.state.setControlVisibleCallback(this.state.visible);
  }

  onInit(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
        this.setControlVisible(false);
  }

  onComplete(canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      let sessionId = DataManager.getSessionId();
      let userId = DataManager.getUserId();
      let hostname = DataManager.getCurrentHostName();
      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.setControlVisible(false);
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId : sessionId,
          userId : userId
        })
      };

      fetch('https://'+hostname+':8080/action/unwait/image', requestOptions).then((response) => {
          if(response.ok){
            return response.json();
          }
      }).then((data) => {});
  }

  onMouseDown(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){

      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();
      // console.log('Image MOUSE DOWN: ' + JSON.stringify(current));

      if (current.state != DrawingState.None){
        return;
      }

      current.state = DrawingState.Drawing;
      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
      current.maxWidth = 0;
      current.maxHeight = 0;

      DataManager.setCurrentDrawingTool(current);
  }

  onMouseMove(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();
      if(current.state != DrawingState.Drawing){
        return;
      }

      //console.log('Image MOUSE MOVE');
      let width = (e.clientX || e.touches[0].clientX) - current.x;
      let height = (e.clientY || e.touches[0].clientY) - current.y;
      current.maxWidth = (current.maxWidth < width) ? width : current.maxWidth;
      current.maxHeight = (current.maxHeight < height) ? height : current.maxHeight;

      let info = {
        x : current.x,
        y : current.y,
        w : width,
        h : height
      };

      let style = {
        borderColor : 'grey', //current.color,
        borderWidth : 1
      };

      DataManager.setCurrentDrawingTool(current);
      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      this.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
  }

  onMouseUp(e, canvas, canvasContext,
      overlayCanvas, overlayCanvasContext){
      //console.log('Image MOUSE UP');
      let current = DataManager.getCurrentDrawingTool();
      let sessionId = DataManager.getSessionId();
      let userId = DataManager.getUserId();
      let hostname = DataManager.getCurrentHostName();
      if(current.state != DrawingState.Drawing){
        return;
      }
      current.state = DrawingState.None;
      DataManager.setCurrentDrawingTool(current);
      if(current.maxWidth > 10 && current.maxHeight > 10){
        this.setControlVisible(true);
      }

      let width = canvas.width;
      let height = canvas.height;
      let drawObjectId = DataManager.getCurrentObjectId();
      DataManager.incrementCurrentObjectId();

      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId : sessionId,
          userId : userId,
          objectId: drawObjectId,
          dimensions : {
              x: current.x / width,
              y: current.y / height,
              w: current.maxWidth / width,
              h: current.maxHeight / height
          }
        })
      };

      fetch('https://'+hostname+':8080/action/await/image', requestOptions).then((response) => {
          if(response.ok){
            return response.json();
          }
      }).then((data) => {});
  }

  drawEmittedData(data,
    canvas, context,
    overlayCanvas, overlayContext,
    userId, sessionId){

    let width = canvas.width;
    let height = canvas.height;

    let info = {
      x: data.x * width,
      y: data.y * height,
      w: data.w * width,
      h: data.h * height
    }

    let style = {
      image: data.image
    }

  //  this.draw(info, style, canvas, context, data.final, false, sessionId);
  // console.log('Image Draw Emitted: ' + data.objectId
  //   + ', Context: ' + (!!data.final && !data.selected));
  this.draw(info, style, canvas,
    (!!data.final && !data.selected) ? context : overlayContext, data.final, false, sessionId);
  }

  //TODO: add draw pdf condition... not just image

  drawImage(info, style, context){
    let { x, y, w, h } = info;
    let { image = '' } = style;

    if(image.length == 0){
      return;
    }
    const imageObj = new Image();

    let postDrawCallbackFunc = null;
    if(this.state.postDrawCallback.length > 0){
      postDrawCallbackFunc = this.state.postDrawCallback[0];
      this.state.postDrawCallback.shift();
    }

    imageObj.onload = function() {
      context.drawImage(imageObj, x, y, w, h);
      // console.log('Done Drawing Image');
      if(!!postDrawCallbackFunc){
        postDrawCallbackFunc();
      }
    };
    imageObj.src = image;
  }

  drawImageArea(info, style, canvas, context){
    let { x, y, w, h } = info;
    let { borderColor = 'black', borderWidth = 1 } = style;

    context.beginPath();
    context.strokeStyle = borderColor;
    context.lineWidth = borderWidth;
    context.setLineDash([5, 10]);
    context.rect(x, y, w, h);
    context.stroke();
    context.setLineDash([]);
  };

  draw(info, style, canvas, context, isFinal, emit, sessionId){
    if(!!this.state.visible){
        this.setControlVisible(false);
    }

    let current = DataManager.getCurrentDrawingTool();

    if(current.tool == DrawingControls.Image &&
        current.state == DrawingState.Drawing){
      this.drawImageArea(info, style, canvas, context);
      return;
    }

    if(current.tool != DrawingControls.Image ||
        current.state == DrawingState.None){
      this.drawImage(info, style, context);
      return;
    }
  }
}

const instance = new ImageTool();
Object.freeze(instance);
export default instance;
