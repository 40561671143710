
import React, { useRef, useEffect, useState } from 'react';
import DataManager from "../data/dataManager";
import "../../styles/tooltip_control.css";

const ToolTipControl = () => {
  const [toolTipState, setToolTipState] = useState(
    {
      visible: false,
      text   : "",
      x      : 0,
      y      : 0
    });

  const measureText = (text, fontSize, fontFace = "Arial") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set the font style the same as your desired text
    context.font = `${fontSize} ${fontFace}`;
    const measurement = context.measureText(text);
    const height = parseInt(fontSize, 10); // A rough estimate, can be refined
    return {width: measurement.width, height: height};
  };

  useEffect(() => {
    //DataManager.addToolTipStateCallback(setToolTipState);
  },[]);

  console.log('Redraw Tool tip Called!!!: '+JSON.stringify(toolTipState));

  //TODO: try measuring text and placing large text at the center of the
  //      screen
  let fontSize = (window.innerHeight * 0.24)+"px";
  let dimensions = measureText(toolTipState.text, fontSize);
  let coordinates = {
    x : (window.innerWidth / 2) - (dimensions.width / 2),
    y : (window.innerHeight / 2) - (dimensions.height / 2)
  }

  return (<div className="ct-tooltip">
    <div style={{
    position: "fixed",
    top : coordinates.y,//90, //toolTipState.y + 100,
    left : coordinates.x,//toolTipState.x - (18),
    visibility: (!!toolTipState.visible) ? 'visible' : 'collapse',
    //backgroundColor: "white",
    color : "grey",//"black",
    fontSize: fontSize,
    zIndex : 6000,
  }}> {toolTipState.text} </div>
  </div>);
};

export default ToolTipControl;
