import React, { useRef, useEffect, useState} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import DataManager, {ControlActions, SaveState} from "../data/dataManager";
import PaymentButton from "./paymentButtonControl";

import "../../styles/payment_control.css";


const stripePromise = loadStripe('pk_test_51Q42q5LnTbxamAyvyHUQjKVyrwLfwkoSn2fTyztEAilqTEncugJVXwgpgTUwAJAQCUASULHpkS6FWMK5WEjZCaAd00VFaMGT9G');

const PaymentControl = ({ onClose }) => {

  const [showPaymentControl, setShowPaymentControl] = useState(false);
  useEffect(() => {
    // console.log('Loaded Notification BAR!!!!: ' + notificationText);
    DataManager.addDisplayPaymentControlCallback('paymentControl', setShowPaymentControl);
  }, []);

  const handleCancel = () => {
    DataManager.setDisplayPaymentControl(false);
  };

  return (
    <div className="overlay-payment" style={{
      display : (!showPaymentControl) ? 'none' : 'flex',
    }}>
      <div className="payment-modal">
        <h2>Confirm Payment</h2>
        <p>You will be charged <strong>$2</strong> to save your session for a period of 30 days.</p>
        <Elements stripe={stripePromise}>
          <PaymentButton amount={200} text="Proceed To Payment"/>
        </Elements>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
}

export default PaymentControl;
