import React, { useRef, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';


const GA_MEASUREMENT_ID = "G-4W1W2FGV07";
const public_hostname = "colabt.com"; //"localhost"; //"ec2-54-160-83-227.compute-1.amazonaws.com"; //"localhost";
const prod_domain="www.colabt.com";

const VerifyCheckoutPage = () => {

  const { myhash } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('VERIFY CHECKOUT: ' + myhash);
    if(!myhash){
      console.log('\n\n Verified Hash is MISSING \n\n');
      navigate('/');
    }

    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
          hash : myhash
        })
    };
    fetch('https://'+ public_hostname + ':8080/verify-checkout', requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log('\n\n Verified Hash sessionId: ' + data.session + '\n\n');
      navigate('/' + data.session);
    })
    .catch(console.error);

    window.gtag("config", GA_MEASUREMENT_ID, {
      page_path: window.location.pathname,
    });
  },[]);
};
export default VerifyCheckoutPage;
