import React, { useRef, useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ColorsToolBar from "../controls/colorsToolBar";
import ControlsToolBar from "../controls/controlsToolBar";
import SessionToolBar from "../controls/sessionToolBar";
import EditTextControl from "../controls/editTextControl";
import EditSelectionControl from "../controls/editSelectionControl";
import QRCodeLinkControl from "../controls/qrCodeLinkControl";
import CameraViewControl from "../controls/cameraViewControl";
import UploadFileControl from "../controls/uploadFileControl";
import NotificationBar from "../controls/notificationBar";
import ToolTipControl from "../controls/toolTipControl";
import PaymentControl from "../controls/paymentControl";
import DataManager, {DrawingState, DrawingControls, ControlActions, SaveState, ColorTheme} from "../data/dataManager";
import EventManager from "../data/eventManager";
import io from 'socket.io-client';
import '../../styles/wh_board_page.css';

//TODO: before publishing to production, make sure public_hostname
// points to EC2 and not to localhost
const GA_MEASUREMENT_ID = "G-4W1W2FGV07";
const public_hostname = "colabt.com"; //"localhost"; //"ec2-54-160-83-227.compute-1.amazonaws.com"; //"localhost";
const prod_domain="www.colabt.com";


const BoardPage = () => {
  const canvasRef = useRef(null);
  const overlayRef = useRef(null);
  const editTextRef = useRef(null);
  const socketRef = useRef();


  const [sessionId, setSessionId] = useState();
  const [userId, setUserId] = useState();
  const [usersList, setUsersList] = useState({});
  const [userPermissions, setUserPermissions] = useState();
  const { mysession } = useParams();
  const navigate = useNavigate();


  //STAGE 0
  if(!sessionId && !!mysession)
  {
    DataManager.setSessionId(mysession);
    setSessionId(mysession);
  }

  //STAGE 1
  useEffect(() => {
    const getSession = () => { fetch('https://'+public_hostname+':8080/session').then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        // console.log("My Session1: " + mysession);
        //navigate('/board2/' + data.data);
        navigate('/' + data.data);
      });
    };

    if(!mysession)
    {
      getSession();
    }

    window.gtag("config", GA_MEASUREMENT_ID, {
      page_path: window.location.pathname,
    });

  },[]);

  //STAGE 2
  useEffect(() => {
    if(!sessionId){
      return;
    }

    DataManager.addSetUserIdCallbackList(setUserId);
    DataManager.addSetUserPermissionsCallbackList(setUserPermissions);

    const getUser = () => { fetch('https://'+public_hostname+':8080/user/' + sessionId).then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        console.log('USER DATA: ' + JSON.stringify(data));
        //TODO: need to make sure that userList gets updated in DataManager

        DataManager.setUserId(data.user);
        DataManager.setUserPermissions(data.permissions);
        setUserId(data.user);
        setUserPermissions(data.permissions)
        if(!!data.error && data.error.length > 0)
        {
          console.log("SETTING NOTIFICATION BAR ERROR!! "+ JSON.stringify(data.error));
          DataManager.setNotificationBarText(data.error, ColorTheme.Red);
          return;
        }
      });
    };

    if(!userId)
    {
      getUser();
    }
  }, [sessionId]);

  //STAGE 3
  useEffect(() => {
    if(!userId){
      return;
    }

    DataManager.setCurrentHostName(public_hostname);
    DataManager.addSetUserListCallbackList("boardPage", setUsersList);

    //TODO: add notificationBar logic to the main page
    // by checking userList and if notificationBar is already displayed.
    //TODO: use a map in DataManager to represent setUserListCallbackList
    // instead of an array.

    const canvas = canvasRef.current;
    const overlayCanvas = overlayRef.current;
    const editControl = editTextRef.current;

    const context = canvas.getContext('2d');
    const overlayContext = overlayCanvas.getContext('2d');

    const onToolInit = (toolInstance)=>{
      if(!toolInstance){
        return;
      }
      toolInstance.onInit(canvas, context,
          overlayCanvas, overlayContext);
    };

    const onToolComplete = (toolInstance)=>{
      if(!toolInstance){
        return;
      }
      toolInstance.onComplete(canvas, context,
          overlayCanvas, overlayContext);
    };

    const onUndoAction = (e) => {
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onUndoAction(e, canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    }

    const onSavePdfAction = (e) => {
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onSavePdfAction(e, canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    }

    const onDownloadAction = (e) => {
      //console.log('Download Action Callback')
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onDownloadAction(e, canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    }

    const onUploadAction = (data) => {
      //console.log('Download Upload Callback')
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onUploadAction(data, canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    }

    const onUploadPdfAction = (data) => {
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onUploadPdfAction(data, canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    }

    const onSaveToDatabase = (callback) => {
      EventManager.onSaveToDatabaseAction(callback);
    }

    DataManager.setToolInitCallback(onToolInit);
    DataManager.setToolCompleteCallback(onToolComplete);
    DataManager.setActionCallback(ControlActions.Undo, onUndoAction);
    DataManager.setActionCallback(ControlActions.SavePdf, onSavePdfAction);
    DataManager.setActionCallback(ControlActions.Download, onDownloadAction);
    DataManager.setActionCallback(ControlActions.Upload, onUploadAction);
    DataManager.setActionCallback(ControlActions.SaveDatabase, onSaveToDatabase);
    DataManager.setActionCallback(ControlActions.UploadPdf, onUploadPdfAction);
    DataManager.addCurrentColorCallback("pointerTool", (color) => {
      // console.log("PointerTool - Color Change CALLBACK");
      let current = DataManager.getCurrentDrawingTool();
      if(current.tool != DrawingControls.Pointer){
        return;
      }

      let info = {
        x : current.x,
        y : current.y
      };

      let style = {
        color : color,
        fadingLineList : current.fadingLineList
      };

      let overlayCanvasContext = overlayContext;

      let pointerTool = DrawingControls.getToolInstance(DrawingControls.Pointer);
      overlayCanvasContext.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      pointerTool.draw(info, style, canvas, overlayCanvasContext, false, true, sessionId);
    });


    const onMouseDown = (e)=>{
      EventManager.onMouseDown(e, canvas, context,
        overlayCanvas, overlayContext);
    }

    const onTouchDown = (e) => {
      e.preventDefault();
      //onMouseDown(e);
      onMouseDown({
        touches : [{
          clientX : e.touches[0].clientX * DataManager.getMobilePixelMultiplier(),
          clientY : e.touches[0].clientY * DataManager.getMobilePixelMultiplier()
        }]
      });
    }

    const onMouseMove = (e)=>{
      EventManager.onMouseMove(e, canvas, context,
        overlayCanvas, overlayContext);
    }

    const onTouchMove = (e) => {
      e.preventDefault();
     //onMouseMove(e);
      onMouseMove({
        touches : [{
          clientX : e.touches[0].clientX * DataManager.getMobilePixelMultiplier(),
          clientY : e.touches[0].clientY * DataManager.getMobilePixelMultiplier()
       }]
      });
    }

    const onMouseUp = (e)=>{
      //console.log('Canvas Key Up!!');
      EventManager.onMouseUp(e, canvas, context,
        overlayCanvas, overlayContext);
    }

    const onTouchUp = (e) => {
      e.preventDefault();
      //onMouseUp(e);
      onMouseUp({
       changedTouches : [{
          clientX : e.changedTouches[0].clientX * DataManager.getMobilePixelMultiplier(),
          clientY : e.changedTouches[0].clientY * DataManager.getMobilePixelMultiplier()
        }]
      });
    }

    const handleOnKeyUp = (e) => {
      if (e.key === 'z' || e.key === 'Z') {  // Checks for both lower and uppercase 'Z'
        if (e.ctrlKey) {
          //console.log('Ctrl+Z was pressed');
          //e.preventDefault();
          onUndoAction(e);
          return;
        }
      }

      EventManager.onKeyUp(e, canvas, context,
        overlayCanvas, overlayContext);
    }

    const throttle = (callback, delay) => {
      let previousCall = new Date().getTime();
      return function() {
        const time = new Date().getTime();

        if ((time - previousCall) >= delay) {
          previousCall = time;
          callback.apply(null, arguments);
        }
      };
    };

    //it is important to subtract the same amount
    //of pixels from the height, as css file does
    const onResize = () => {
      let data = {
        height : window.innerHeight,
        width : window.innerWidth
      };
      // console.log('RESIZING  w: ' + window.innerWidth + ', h: ' + window.innerHeight);
      EventManager.onResize(data,
        canvas, context, overlayCanvas, overlayContext);

      fetchInit();
    };

    const onDrawingEvent = (data) => {
      let userId = DataManager.getUserId();
      let sessionId = DataManager.getSessionId();
      // console.log('Data userId: ' + data.userId + ', userId: ' + userId);

      if((!!data.actingUserId && data.actingUserId == userId && data.userId == userId) ||
        (!data.actingUserId && data.userId == userId) ||
        (''+sessionId != ''+data.sessionId)){
        // console.log('Exiting');
        return;
      }
      //TODO: text updates but need to deselect too
      // console.log('DRAWING EVENT CanvasId: ' + "canvas_" + data.userId);
      //User id is local so no overlay
      let localUserCanvas = (!!data.actingUserId)
        ? document.getElementById("canvas_"+data.actingUserId)
        : document.getElementById("canvas_"+data.userId);
      let localUserCanvasContext = localUserCanvas.getContext('2d');
      EventManager.onDrawingEvent(data,
         canvas, context,
         overlayCanvas, overlayContext,
         localUserCanvas, localUserCanvasContext);
    };

    const onRefreshEvent = (data) => {
      let localUserCanvas = overlayCanvas;
      let localUserCanvasContext = overlayContext;
      EventManager.onRefreshEvent(data,
        canvas, context,
        overlayCanvas, overlayContext,
        localUserCanvas, localUserCanvasContext);
    };

    const onClearCanvasForUser = (data) => {
      // console.log('ON CLEAR CALL HANDLE BOARD PAGE');
      if(data.sessionId != sessionId){
        return;
      }

      if(!data.userId || data.userId == userId){
        return;
      }

      let localUserCanvas = document.getElementById("canvas_"+data.userId);
      let localUserCanvasContext = localUserCanvas.getContext('2d');

      EventManager.onClearCanvasEvent(data,
         localUserCanvas, localUserCanvasContext);
    }


    const onUpdateUsers = (data) => {
      // console.log('UPDATE RECEIVED USER ID:' + data.user);
      if(data.sessionId != sessionId)
      {
        return;
      }

      if(!data.user || data.user == userId){
        return;
      }

      // console.log('UPDATE USER PASSED CHECKS:' + JSON.stringify(data.userList));
      DataManager.setUserList(data.userList);
      // let numUsers = Object.keys(data.userList).length;
      // console.log('ON UPDATE USERS LENGTH: ' + numUsers);
      // if(numUsers == 1){
      //   console.log('UPDATING NOTIFICATION MESSAGE!!!');
      //   DataManager.setNotificationBarText("You are the last user in session. Session will be deleted once you leave!");
      // } else {
      //   DataManager.setNotificationBarText("");
      // }
    }


    const fetchInit = () => { fetch('https://'+public_hostname+':8080/get/'+sessionId).then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        console.log("SESSION GET RESPONSE DATA: ", JSON.stringify(data));
        let localUserCanvas = overlayCanvas;
        let localUserCanvasContext = overlayContext;

        DataManager.setUserList(data.users);
        DataManager.setDatabaseState({
          saved: (!data.paid) ? SaveState.None : SaveState.Saved,
          message : "",
          dateSaved: null,
          datePaid : data.paid
        });
        EventManager.onDrawInit(data.data,
          canvas, context,
          overlayCanvas, overlayContext,
          localUserCanvas, localUserCanvasContext);
        console.log("CHECKING PAID!! "+ JSON.stringify(data.paid));
        console.log("CHECKING ERROR!! "+ JSON.stringify(data.error));
        if(!!data.error && data.error.length > 0)
        {
          console.log("SETTING NOTIFICATION BAR ERROR!! "+ JSON.stringify(data.error));

          DataManager.setNotificationBarText(data.error, ColorTheme.Red);
          return;
        }

        if(!!data.paid && data.paid > 0){
            DataManager.setNotificationBarText("There are " + DataManager.getDayPaidDaysLeft(data.paid)
             +" days left for session in the cloud!", ColorTheme.Green);
        }
      });
    };

    const onWebsiteUnload = () => {
      EventManager.onLeaveWebsiteAction();
    };

    const onUpdateBackgroundImage = (data) => {
      //console.log('Received image data broadcast!! '+ JSON.stringify(data))
      if(data.sessionId != sessionId){
        return;
      }

      //let localUserCanvas = document.getElementById("canvas_"+data.userId);
      //let localUserCanvasContext = localUserCanvas.getContext('2d');
      EventManager.onDrawingEventUnrestrictive(data,
         canvas, context,
         overlayCanvas, overlayContext,
         overlayCanvas, overlayContext);

      // const image = new Image();
      // image.onload = function() {
      //   context.drawImage(image, data.x, data.y - 155);
      // };
      // image.src = data.image;
    };

    const onSaveToDatabaseNotification = (data) => {
      if(data.session != sessionId || data.userId == userId){
        return;
      }

      //TODO: !!!! make sure message did not originate from the same user

      DataManager.setDatabaseState({
        saved: (!data.success) ? SaveState.Failed : SaveState.Saved,
        message : (!data.success) ? "Error: " + data.error : "",
        dateSaved: null,
        datePaid : data.paid
      });

      if(!!data.paid && data.paid > 0){
        DataManager.setNotificationBarText("Session is saved to the cloud for "
          + DataManager.getDayPaidDaysLeft(data.paid) +" days!", ColorTheme.Green);
      }
    }

    window.addEventListener('resize', onResize, false);
    window.addEventListener('beforeunload', onWebsiteUnload);
    window.addEventListener('keyup', handleOnKeyUp, false);
    onResize();

    // console.log('>>>>> ADDDING LISTENERS!!!!!!');
    //Click support for web browsers
    overlayCanvas.addEventListener('mousedown', onMouseDown, false);
    overlayCanvas.addEventListener('mouseup', onMouseUp, false);
    overlayCanvas.addEventListener('mouseout', onMouseUp, false);
    overlayCanvas.addEventListener('mousemove', throttle(onMouseMove, 10), false);

    //Touch support for mobile devices
    // overlayCanvas.addEventListener('touchstart', onMouseDown, false);
    // overlayCanvas.addEventListener('touchend', onMouseUp, false);
    // overlayCanvas.addEventListener('touchcancel', onMouseUp, false);
    // overlayCanvas.addEventListener('touchmove', throttle(onMouseMove, 10), false);
    overlayCanvas.addEventListener('touchstart', onTouchDown, false);
    overlayCanvas.addEventListener('touchend', onTouchUp, false);
    overlayCanvas.addEventListener('touchcancel', onTouchUp, false);
    overlayCanvas.addEventListener('touchmove', throttle(onTouchMove, 10), false);

    socketRef.current = io.connect('https://'+public_hostname+':8080');
    EventManager.setEmitSocketReference(socketRef.current);
    // console.log('Sending UserId: ' + userId);
    socketRef.current.emit('updateusers', { user : userId, sessionId : sessionId });

    socketRef.current.on('drawing', onDrawingEvent);
    socketRef.current.on('refresh', onRefreshEvent);
    socketRef.current.on('updateusers', onUpdateUsers);
    socketRef.current.on('clear', onClearCanvasForUser);
    socketRef.current.on('updatebackground', onUpdateBackgroundImage);
    socketRef.current.on('databasesave', onSaveToDatabaseNotification);
  }, [userId]);

  useEffect(() => {
    let numUsers = Object.keys(usersList).length;
    // console.log('ON UPDATE USERS LENGTH: ' + numUsers);
    // if(numUsers == 1){
    //   console.log('UPDATING NOTIFICATION MESSAGE!!!');
    //   DataManager.setNotificationBarText("You are the only user in session. Session will be deleted once you leave!");
    // } else {
    //   DataManager.setNotificationBarText("");
    // }
  }, [usersList]);

  let canvasList = DataManager.getUserOverlaysList();
  let zIndexCount = (!usersList) ? 0 : Object.keys(usersList).length;
  //let cameraUrl = 'http://'+public_hostname+':3000/camera'
  let cameraUrl = 'https://'+public_hostname+'/camera'

  let imageControl = (window.innerWidth > 950)
    ? <QRCodeLinkControl url={cameraUrl} session={sessionId} user={userId}/>
    :   <CameraViewControl
        width={640}
        height={480}
        visible={false}
        session={sessionId}
        user={userId}/>;

  return (
      <div>
      <canvas ref={canvasRef} className="whiteboard"/>
      {canvasList}
      <canvas ref={overlayRef} className="whiteboard overlay"
        style={{
          zIndex : (!usersList) ? 1 : Object.keys(usersList).length + 1
        }}/>
      <ToolTipControl/>
      <NotificationBar timed={true}/>
      <SessionToolBar/>
      <ControlsToolBar/>
      <ColorsToolBar/>
      {imageControl}
      <UploadFileControl canvasRef={canvasRef} hostname={public_hostname} session={sessionId} user={userId}/>
      <EditTextControl canvasRef={canvasRef} overlayRef={overlayRef} />
      <EditSelectionControl canvasRef={canvasRef} overlayRef={overlayRef} />
      <PaymentControl/>
      </div>
  );
};
export default BoardPage;
