import React, { useRef, useEffect, useState } from 'react';
import DataManager, {DrawingState, DrawingControls} from "../data/dataManager";
import ImageTool from "../data/tools/imageTool";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave, faSlash, faPen,
  faSquareFull, faFont,
  faArrowPointer, faRotateLeft, faFilePdf, faEraser, faBorderStyle, faUpload, faCloudArrowUp} from '@fortawesome/free-solid-svg-icons';
import {faSquare, faClipboard, faSquareDashed} from '@fortawesome/free-regular-svg-icons';
import "../../styles/board.css";


const UploadFileControl = ({canvasRef, hostname, session, user}) => {
  const inputRef = useRef(null);
  //const canvasRef = useRef(null); //TODO: pass canvas reference


  const [fullUrl, setFullUrl] = useState();
  const [uploadFileControlVisible, setUploadFileControlVisible] = useState(false);
  const [redrawFlag, setRedrawFlag] = useState(false);
  const [image, setImage] = useState(null);

  const current = DataManager.getCurrentDrawingTool();

  const handleUploadClick = () => {
    // console.log('upload button pressed');
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    console.log('handle file change is called');
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      console.log('FILE SELECTED IS PDF!!');
      // const fileReader = new FileReader();
      //  fileReader.onload = async (e) => {
      //    uploadPdfFile(e);
      //  };
      const reader = new FileReader();
      reader.onload = function (e) {
          console.log('Upload PDF File!!');
          uploadPdfFile(file);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
      return;
    }

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function (e) {
          uploadFile(file);
      };
      reader.readAsDataURL(file);
      //console.log('Image File is picked!!!');
    }

    event.target.value = '';
  };

  const uploadPdfFile = (file) => {
    console.log('Upload PDF FILE CALLED!!!');

    const formData = new FormData();
    formData.append('photo', file);
    formData.append('session', session);
    formData.append('user', user);
    fetch('https://'+hostname+':8080/action/upload/pdf', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
        console.log('GOT PDF RESPONSE!!!');
        console.log(data);
      })
    .catch(console.error);
  };

  const uploadFile = (file) =>{
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('session', session);
    formData.append('user', user);

    fetch('https://'+hostname+':8080/action/upload/image', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(console.error);
  };

  useEffect(() => {
    setFullUrl("https://" + hostname + "/" +session + "/" + user);
  },[hostname, session, user]);

  useEffect(() => {
    ImageTool.setVisibleCallback('UploadFileControl', setUploadFileControlVisible);
    ImageTool.setRedrawCallback('UploadFileControl', setRedrawFlag);
  },[fullUrl]);

  return (
    <div>
      <input
          type="file"
          ref={inputRef}
          accept="image/*, application/pdf"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      <div onClick={handleUploadClick} style={{
        position : 'fixed',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'grey',
        borderRadius: '15px',
        top: (current.maxHeight > 130) ? current.y - 36 : (current.y + (current.maxHeight / 2 - 65) - 36),
        left: (current.maxWidth > 130) ? current.x + current.maxWidth - 40 : (current.x + (current.maxWidth / 2 - 65) + 130 - 40),
        width: '40px',
        height: '30px',
        visibility: !!uploadFileControlVisible ? 'visible' : 'collapse',
        zIndex: 1000,
        backgroundColor: 'white'
      }}><FontAwesomeIcon style={{
        position : 'relative',
        left: '10px',
        top: '5px',
        //color: 'grey'
      }} icon={faCloudArrowUp} /></div>
    </div>
  );
}

export default UploadFileControl;
